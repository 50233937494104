/*
 * @Descripttion:
 * @Author: Chen Zhi
 * @Date: 2021-05-28 12:05:11
 * @LastEditors: Chen Zhi
 * @LastEditTime: 2021-11-12 17:03:47
 */
export type Platform =
  | 'kixmix'
  | 'koznak'
  | 'qq'
  | 'weixin'
  | 'taktak'
  | undefined
export function getPlatform(): Platform {
  const ua = navigator.userAgent
  if (/kixmix/i.test(ua)) {
    return 'kixmix'
  } else if (/koznak/i.test(ua)) {
    return 'koznak'
  } else if (/qq/i.test(ua)) {
    return 'qq'
  } else if (/microMessenger/i.test(ua)) {
    return 'weixin'
  } else if (/taktak/i.test(ua)) {
    return 'taktak'
  } else {
    return undefined
  }
}
